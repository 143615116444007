import React, { useState, useEffect } from "react";
import { usdtIcon } from "../../assets";
//import { Listbox, Transition } from "@headlessui/react";
//import { Select, Option } from "@material-tailwind/react";
import * as Select from "@radix-ui/react-select";
import classnames from "classnames";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { TextInput } from "../layouts/TextInput/number";
import { getCurrencyImage } from "../../utils";
import Assets from "../../utils/assets";

/* const assets = [
  {
    id: 2,
    name: "DUSD",
    image:
      "https://res.cloudinary.com/dfxwwg0cm/image/upload/v1678698564/dexp2p-app/svg/dUSD_Icon_czgnhz.png",
  },
] */

const fiats = [{ id: 1, name: "NGN", image: Assets.NAIRA_ICON }];

const ToastOptions = {
  duration: 6000,
  style: {
    fontFamily: "Gellix",
  },
};

const SelectItem = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={classnames(
          "text-[13px] leading-none text-violet11 rounded-[3px] flex items-center h-[25px] pr-[35px] pl-[25px] relative select-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1",
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);

const ExpressConverter = () => {
  const [tab, setTab] = useState("Buy");
  const [amountCurrencies, setAmountCurrencies] = useState(fiats);
  const [assets, setAssets] = useState([]);
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [amountToReceiveCurrencies, setAmountToReceiveCurrencies] =
    useState(assets);

  const [formData, setFormData] = useState({
    orderType: "",
    amount: "",
    amountToReceive: "",
    fiat: "NGN",
    asset: amountToReceiveCurrencies && amountToReceiveCurrencies[1]?.name,
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeFiat = (e) => {
    setFormData({
      ...formData,
      fiat: e.target.value,
    });
  };

  const onChangeAsset = (e) => {
    console.log("here???????????????" + e)

    setFormData({
      ...formData,
      asset: e,
    });
  };

  const { orderType, amount, amountToReceive, fiat, asset } = formData;

  const handleProceedClick = () => {
    window.location.replace(
      `${
        process.env.REACT_APP_MAIN_APP_BASE_URL
      }?order=express&orderType=${tab.toLowerCase()}&amount=${amount}&fiat=${fiat}&asset=${asset}`
    );
  };

  const handleChangeTab = (value) => {
    setTab(value);
    if (value == "Buy") {
      setAmountCurrencies(fiats);
      setAmountToReceiveCurrencies(assets);
      setFormData({
        ...formData,
        fiat: "NGN",
        asset: amountCurrencies && amountCurrencies[1]?.name,
      });
    } else {
      setAmountCurrencies(assets);
      setAmountToReceiveCurrencies(fiats);
      setFormData({
        ...formData,
        asset: amountToReceiveCurrencies && amountToReceiveCurrencies[1]?.name,
        fiat: "NGN",
      });
    }
  };

  useEffect(() => {
    const getAmountToRecieve = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_BASE_URL
          }/trades/express/${asset}/${tab.toLowerCase()}`
        );
        if (response){
          if (response.data.price == 0) {
            toast.error("No ads match", {
              id: "error",
              duration: 1000
            });
          }
           setPrice(response.data.price);
          }

        /*  setFormData({
            ...formData,
            amountToReceive: response.data.price,
          }); */

        /*  console.log("this is response " + JSON.stringify(response)); */
      } catch (error) {
        toast.error(error.response.data.message, {
          id: "error",
        });
      }
    };

   if (amount !== "" ) getAmountToRecieve();
  }, [amount, tab, asset]);

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/assets`
        );
        if (response) {
          setAmountToReceiveCurrencies(response.data);
          setFormData({
            orderType: "",
            amount: "",
            amountToReceive: "",
            fiat: "NGN",
            asset: response.data[1]?.name,
          });
          setAssets(response.data);
          setLoading(false);
        }
        /*  console.log("this is response " + JSON.stringify(response)); */
      } catch (error) {
        toast.error(error.response.data.message, {
          id: "error",
        });
        setLoading(false);
      }
    };
    fetchAssets();
  }, []);
  //
  if (loading) {
    return <> Loading</>;
  } else {
    return (
      <div className="flex flex-col bg-[#1C1E26] justify-start items-start rounded-[8px] w-[520px] mt-2 mx-6 px-4 md:px-6 py-8  gap-[24px]">
        <div className="flex flex-row justify-between items-center h-[47px] rounded-[32px] border border-solid border-1 border-[#BEBEBE] w-full bg-[#111217] p-[5px]">
          <div
            className="flex flex-row justify-center items-center text-white text-lg font-normal w-full cursor-pointer h-full rounded-[36px]"
            onClick={() => handleChangeTab("Buy")}
            style={{
              backgroundColor: tab == "Buy" ? "#292B37" : "#111217",
            }}
          >
            Buy
          </div>
          <div
            className="flex flex-row justify-center items-center text-white text-lg font-normal w-full cursor-pointer h-full rounded-[36px] "
            onClick={() => handleChangeTab("Sell")}
            style={{
              backgroundColor: tab == "Sell" ? "#292B37" : "#111217",
            }}
          >
            Sell
          </div>
        </div>

        {/* Amount to Buy */}
        <div className="flex flex-col w-full">
          <h6 className="text-[#CBCBCB] text-base font-normal mb-2">
            {" "}
            Amount to {tab.toLowerCase()}{" "}
          </h6>
          <div className="flex flex-row justify-between">
            <TextInput
              value={amount}
              name="amount"
              onChange={onChange}
              className="flex rounded-[32px] bg-[#1C1E26] w-[63%] md:w-[74%] h-[48px] border border-1 border-solid border-[#636363] text-white p-4 "
            />

            {/*  <input
              type="number"
              value={amount}
              name="amount"
              onChange={onChange}
              className="flex rounded-[32px] bg-[#1C1E26] w-[63%] md:w-[74%] h-[48px] border border-1 border-solid border-[#636363] text-white p-4 "
            />{" "} */}
            <div className="w-[35%] md:w-[23%] text-white">
              <Select.Root
                value={tab === "Buy" ? fiat : asset}
                onValueChange={tab === "Buy" ? onChangeFiat : onChangeAsset}
              >
                <Select.Trigger
                  className="flex flex-row items-center justify-between rounded-[32px] bg-[#1C1E26] h-[48px] px-3 py-2 border border-1 border-solid border-[#636363] text-base font-medium text-white p-4 w-full"
                  aria-label="Food"
                >
                  <Select.Value placeholder="Select" />
                  <Select.Icon className="text-white">
                    <ChevronDownIcon />
                  </Select.Icon>
                </Select.Trigger>
                <Select.Portal>
                  <Select.Content className="overflow-hidden !bg-[#1C1E26] !text-white text-base font-medium ">
                    <Select.ScrollUpButton className="flex items-center justify-center h-[25px] !bg-[#1C1E26] text-white cursor-default">
                      <ChevronUpIcon />
                    </Select.ScrollUpButton>
                    <Select.Viewport className="p-[5px]">
                      <Select.Group>
                        {amountCurrencies.map(({ name }) => (
                          <SelectItem key={name} value={name}>
                            <span className="!flex flex-row justify-between items-center !bg-[#1C1E26] text-sm font-medium ">
                              <img
                                src={getCurrencyImage(name)}
                                alt={name}
                                className="mr-2 md:mr-[8px]"
                                style={{
                                  verticalAlign: "middle",
                                  height: "20px",
                                }}
                              />
                              {name}
                            </span>
                          </SelectItem>
                        ))}
                      </Select.Group>
                    </Select.Viewport>
                    <Select.ScrollDownButton className="flex items-center justify-center h-[25px] !bg-[#1C1E26] text-white cursor-default">
                      <ChevronDownIcon />
                    </Select.ScrollDownButton>
                  </Select.Content>
                </Select.Portal>
              </Select.Root>

              {/*  <Select
                onChange={onChangeAmountToReceiveCurrency}
                value={ammountToReceiveCurrency}
                className="flex flex-row items-center justify-between rounded-[32px] bg-[#1C1E26] h-[48px] px-3 py-2 border border-1 border-solid border-[#636363] text-white p-4 "
                style={{
                  border: "1px solid #636363",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#1C1E26",
                }}
                selected={(element) =>
                  element &&
                  React.cloneElement(element, {
                    disabled: true,
                    className:
                      "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                  })
                }
              >
                {currencies.map(({ name, image }) => (
                  <Option
                    key={name}
                    value={name}
                    className="flex flex-row justify-between w-full items-center bg-[#1C1E26]"
                  >
                    <span className="flex flex-row justify-between items-center">
                      <img
                        src={image}
                        alt={name}
                        className=" "
                        style={{
                          verticalAlign: "middle",
                          marginRight: "11px",
                          height: "20px",
                        }}
                      />
                      {name}
                    </span>
                  </Option>
                ))}
              </Select> */}
            </div>
          </div>
          <h6 className="text-[#CBCBCB] text-xs font-normal mt-1">
            {" "}
            1.00 {asset} = {fiat} {price}{" "}
          </h6>
        </div>

        {/*  Amount to Receive */}

        <div className="flex flex-col mb-[30px] w-full">
          <h6 className="text-[#CBCBCB] text-base font-normal mb-2">
            {" "}
            Amount to receive{" "}
          </h6>
          <div className="flex flex-row justify-between">
            <TextInput
              value={
                tab == "Sell"
                  ? Number(String(Number(price) * Number(amount))).toFixed(2)
                  : Number(String(Number(amount) / Number(price))).toFixed(2)
              }
              name="aamountToReceive"
              onChange={onChange}
              disabled
              className="flex rounded-[32px] bg-[#1C1E26] w-[63%] md:w-[74%] h-[48px] border border-1 border-solid border-[#636363] !text-white p-4 "
            />

            <div className="w-[35%] md:w-[23%] text-white">
              <Select.Root
                value={tab === "Buy" ? asset : fiat}
                onValueChange={tab === "Buy" ? onChangeAsset : onChangeFiat}
              >
                <Select.Trigger
                  className="flex flex-row items-center justify-between rounded-[32px] bg-[#1C1E26] h-[48px] px-3 py-2 border border-1 border-solid border-[#636363] text-base font-medium text-white p-4 w-full"
                  aria-label="Food"
                >
                  <Select.Value placeholder="Select" />
                  <Select.Icon className="text-white">
                    <ChevronDownIcon />
                  </Select.Icon>
                </Select.Trigger>
                <Select.Portal>
                  <Select.Content className="overflow-hidden !bg-[#1C1E26] !text-white text-base font-medium ">
                    <Select.ScrollUpButton className="flex items-center justify-center h-[25px] !bg-[#1C1E26] text-white cursor-default">
                      <ChevronUpIcon />
                    </Select.ScrollUpButton>
                    <Select.Viewport className="p-[5px]">
                      <Select.Group>
                        {amountToReceiveCurrencies.map(({ name }) => (
                          <SelectItem key={name} value={name}>
                            <span className="!flex flex-row justify-between items-center !bg-[#1C1E26] text-sm font-medium ">
                              <img
                                src={getCurrencyImage(name)}
                                alt={name}
                                className="mr-2 md:mr-[8px]"
                                style={{
                                  verticalAlign: "middle",
                                  height: "20px",
                                }}
                              />
                              {name}
                            </span>
                          </SelectItem>
                        ))}
                      </Select.Group>
                    </Select.Viewport>
                    <Select.ScrollDownButton className="flex items-center justify-center h-[25px] !bg-[#1C1E26] text-white cursor-default">
                      <ChevronDownIcon />
                    </Select.ScrollDownButton>
                  </Select.Content>
                </Select.Portal>
              </Select.Root>
             
            </div>

          </div>
        </div>

        {/*  Proceed button */}
        <button
          onClick={handleProceedClick}
          className="bg-white rounded-[64px] p-3 h-[48px] text-[#111217] text-base font-medium w-full flex flex-col justify-center items-center"
        >
          Proceed to {tab.toLowerCase()}
        </button>
        <Toaster position="bottom-right" toastOptions={ToastOptions} />
      </div>
    );
  }
};

export default ExpressConverter;
