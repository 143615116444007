import React, { useState, Fragment } from "react"
import {
  close,
  logo,
  menu,
  pdfIcon,
  tweet,
  utube,
  midi,
  gram,
  discod,
} from "../assets"
import { Link } from "react-router-dom"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const Navbar = () => {
  const [toggle, setToggle] = useState(false)

  return (
    <div className='bg-primary   max-sm:border-b-[1px] max-sm:border-b-solid max-sm:border-b-[#1a1c23] w-full h-24 max-sm:h-[71px]'>
      <hr className='max-w-[1440px] bg-lineColor absolute' />
      <nav className='bg-primary flex flex-row  justify-between items-center px-[0px] pt-[25px] pb-[22px] max-sm:py-[17px]  '>
        <Link to='/'>
          <img
            src={logo}
            className='w-[45px]  h-[45px] max-sm:w-[36px] max-sm:h-[36px]'
            alt='DexPay'
          />
        </Link>

        <ul className='space-x-8  text-sm sm:flex hidden font-medium text-dimWhite '>
          {/* FAQ */}
          <li>
            <Link to='/faq'>FAQ</Link>
          </li>
          {/* Learn */}
          <li>
            <Menu as='div' className='relative inline-block text-left'>
              <Menu.Button className='inline-flex w-full justify-center rounded-md bg-primary bg-opacity-20 px-4  text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
                Learn
                <ChevronDownIcon
                  className='ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100'
                  aria-hidden='true'
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className=' absolute py-[24px] left-0 z-10 mt-2 w-56 border-box flex flex-row items-start gap-2 border-2 border-solid border-[#1E2029] rounded-lg origin-top-right divide-y divide-[#16171D]  bg-[#16171D] shadow-lg focus:outline-none'>
                  <div className='p-0 flex flex-col items-start gap-8'>
                    {/* Medium */}
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href='https://docs.dexpay.io'
                          target='_blank'
                          className={classNames(
                            active ? " text-dimWhite" : "text-[#7D7D7D]",
                            "flex flex-row items-center gap-2 px-4 "
                          )}
                        >
                          <img src={midi} alt='medium' />{" "}
                          <span className=' font-medium text-base leading-[19px]'>
                            Documentation
                          </span>
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href='https://medium.com/@dexpay'
                          target='_blank'
                          className={classNames(
                            active ? " text-dimWhite" : "text-[#7D7D7D]",
                            "flex flex-row items-center gap-2 px-4 "
                          )}
                        >
                          <img src={midi} alt='medium' />{" "}
                          <span className=' font-medium text-base leading-[19px]'>
                            Medium
                          </span>
                        </a>
                      )}
                    </Menu.Item>
                    {/* Technical Paper */}
                    {/*      <Menu.Item>
                      {({ active }) => (
                        <Link
                          to='/technicalPaper'
                          target='_blank'
                          className={classNames(
                            active ? " text-dimWhite" : "text-[#7D7D7D]",
                            "flex flex-row items-center gap-2 px-4 "
                          )}
                        >
                          <img src={pdfIcon} alt='medium' />{" "}
                          <span className=' font-medium text-base leading-[19px]'>
                            Technical Paper
                          </span>
                        </Link>
                      )}
                    </Menu.Item> */}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </li>
          {/* Contact us */}
          <li>
            <Link to='/contact'>Contact Us</Link>
          </li>
          {/* Community */}
          <li>
            <Menu as='div' className='relative inline-block text-left'>
              <Menu.Button className='inline-flex w-full justify-center rounded-md bg-primary bg-opacity-20 px-4  text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
                Community
                <ChevronDownIcon
                  className='ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100'
                  aria-hidden='true'
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className=' absolute py-[24px] left-0 z-10 mt-2 w-56 border-box flex flex-row items-start gap-2 border-2 border-solid border-[#1E2029] rounded-lg origin-top-right divide-y divide-[#16171D]  bg-[#16171D] shadow-lg focus:outline-none'>
                  <div className='p-0 flex flex-col items-start gap-8'>
                    {/* Twitter */}
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href='https://twitter.com/usedexpay'
                          target='_blank'
                          className={classNames(
                            active ? " text-dimWhite" : "text-[#7D7D7D]",
                            "flex flex-row items-center gap-2 px-4 "
                          )}
                        >
                          <img src={tweet} alt='twitter' />{" "}
                          <span className=' font-medium text-base leading-[19px]'>
                            Twitter
                          </span>
                        </a>
                      )}
                    </Menu.Item>
                    {/* Discord */}
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href='https://discord.gg/Bf76y58GDJ'
                          target='_blank'
                          className={classNames(
                            active ? " text-dimWhite" : "text-[#7D7D7D]",
                            "flex flex-row items-center gap-2 px-4 "
                          )}
                        >
                          <img src={discod} alt='discord' />{" "}
                          <span className=' font-medium text-base leading-[19px]'>
                            Discord
                          </span>
                        </a>
                      )}
                    </Menu.Item>
                    {/* Youtube */}
                    {/*  <Menu.Item>
                      {({ active }) => (
                        <a
                          href='https://www.youtube.com/channel/UCkXV_rQv_ez_FTJm3LpEtXA'
                          target='_blank'
                          className={classNames(
                            active ? " text-dimWhite" : "text-[#7D7D7D]",
                            "flex flex-row items-center gap-2 px-4 "
                          )}
                        >
                          <img src={utube} alt='youtube' />{" "}
                          <span className=' font-medium text-base leading-[19px]'>
                            Youtube
                          </span>
                        </a>
                      )}
                    </Menu.Item> */}

                    {/* Telegram */}
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href='https://t.me/usedexpay'
                          target='_blank'
                          className={classNames(
                            active ? " text-dimWhite" : "text-[#7D7D7D]",
                            "flex flex-row items-center gap-2 px-4 "
                          )}
                        >
                          <img src={gram} alt='telegram' />{" "}
                          <span className=' font-medium text-base leading-[19px]'>
                            Telegram
                          </span>
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </li>
        </ul>

        {/* MOBILE VERSION START */}

        <div className='sm:hidden  flex flex-1 justify-end items-center'>
          <div className=' max-sm:py-[10px] max-sm:px-[18px]  bg-dimRed flex-row items-end mr-[25px]  px-[18px] py-[12px] rounded-full hover:bg-pink duration-300 flex'>
            <Link
              to='https://app.dexpay.io'
              target='_blank'
              className='flex flex-column justify-center items-center'
            >
              <button className='flex flex-column justify-center items-center max-sm:text-[11px] text-dimWhite text-sm font-medium '>
                Launch App
              </button>
            </Link>
          </div>
          <img
            src={toggle ? close : menu}
            alt='menu'
            className='w-[24px] h-[24px] max-sm:w-[14px] max-sm:h-[14px] object-contain cursor-pointer'
            onClick={() => setToggle(!toggle)}
          />
          <div
            className={`${
              toggle ? "flex" : "hidden"
            } p-4 bg-primary z-20 absolute top-16 right-0 h-3/6 my-0 min-w-[140px] rounded-bl-lg`}
          >
            <ul className='  mt-[-40px] justify-evenly items-center text-sm flex font-medium text-dimWhite flex-col'>
              {/* FAQ */}
              <li onClick={() => setToggle(!toggle)}>
                <Link to='/faq'>FAQ</Link>
              </li>
              {/* Learn */}
              <li>
                <Menu as='div' className='relative inline-block text-left'>
                  <Menu.Button className='inline-flex w-full justify-center rounded-md bg-primary bg-opacity-20 px-4  text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
                    Learn
                    <ChevronDownIcon
                      className='ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100'
                      aria-hidden='true'
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute py-[24px] right-0 z-10 mt-2 w-40 border-box flex flex-row items-start gap-2 border-2 border-solid border-[#1E2029] rounded-lg origin-top-right divide-y divide-[#16171D]  bg-[#16171D] shadow-lg focus:outline-none'>
                      <div className='p-0 flex flex-col items-start gap-4'>
                        {/* Medium */}
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href='https://docs.dexpay.io'
                              target='_blank'
                              className={classNames(
                                active ? " text-dimWhite" : "text-[#7D7D7D]",
                                "flex flex-row items-center gap-2 px-4 "
                              )}
                            >
                              <img src={midi} alt='medium' />{" "}
                              <span className=' font-medium text-base leading-[19px]'>
                                Documentation
                              </span>
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href='https://medium.com/@dexpay'
                              target='_blank'
                              className={classNames(
                                active ? " text-dimWhite" : "text-[#7D7D7D]",
                                "flex flex-row items-center gap-2 px-4 "
                              )}
                            >
                              <img src={midi} alt='medium' />{" "}
                              <span className=' font-medium text-base leading-[19px]'>
                                Medium
                              </span>
                            </a>
                          )}
                        </Menu.Item>
                        {/* Technical Paper */}
                        {/* <Menu.Item>
                          {({ active }) => (
                            <Link
                              to='/technicalPaper'
                              target='_blank'
                              className={classNames(
                                active ? " text-dimWhite" : "text-[#7D7D7D]",
                                "flex flex-row items-center gap-2 px-4 "
                              )}
                            >
                              <img src={pdfIcon} alt='medium' />{" "}
                              <span className=' font-medium text-base leading-[19px]'>
                                Technical Paper
                              </span>
                            </Link>
                          )}
                        </Menu.Item> */}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </li>
              {/* Contact Us */}
              <li onClick={() => setToggle(!toggle)}>
                <Link to='/contact'>Contact Us</Link>
              </li>
              {/* Community */}
              <li>
                <Menu as='div' className='relative inline-block text-left'>
                  <Menu.Button className='inline-flex w-full justify-center rounded-md bg-primary bg-opacity-20 px-4 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
                    Community
                    <ChevronDownIcon
                      className='ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100'
                      aria-hidden='true'
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute py-[24px] right-0 z-10 mt-2 w-40 border-box flex flex-row items-start gap-2 border-2 border-solid border-[#1E2029] rounded-lg origin-top-right divide-y divide-[#16171D]  bg-[#16171D] shadow-lg focus:outline-none'>
                      <div className='p-0 flex flex-col items-start gap-4'>
                        {/* Twitter */}
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href='https://twitter.com/usedexpay'
                              target='_blank'
                              className={classNames(
                                active ? " text-dimWhite" : "text-[#7D7D7D]",
                                "flex flex-row items-center gap-2 px-4 "
                              )}
                            >
                              <img src={tweet} alt='twitter' />{" "}
                              <span className=' font-medium text-base leading-[19px]'>
                                Twitter
                              </span>
                            </a>
                          )}
                        </Menu.Item>
                        {/* Discord */}
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href='https://discord.gg/Bf76y58GDJ'
                              target='_blank'
                              className={classNames(
                                active ? " text-dimWhite" : "text-[#7D7D7D]",
                                "flex flex-row items-center gap-2 px-4 "
                              )}
                            >
                              <img src={discod} alt='discord' />{" "}
                              <span className=' font-medium text-base leading-[19px]'>
                                Discord
                              </span>
                            </a>
                          )}
                        </Menu.Item>
                        {/* Youtube */}
                        {/*  <Menu.Item>
                          {({ active }) => (
                            <a
                              href='https://www.youtube.com/channel/UCkXV_rQv_ez_FTJm3LpEtXA'
                              target='_blank'
                              className={classNames(
                                active ? " text-dimWhite" : "text-[#7D7D7D]",
                                "flex flex-row items-center gap-2 px-4 "
                              )}
                            >
                              <img src={utube} alt='youtube' />{" "}
                              <span className=' font-medium text-base leading-[19px]'>
                                Youtube
                              </span>
                            </a>
                          )}
                        </Menu.Item> */}
                        {/* Telegram */}
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href='https://t.me/usedexpay'
                              target='_blank'
                              className={classNames(
                                active ? " text-dimWhite" : "text-[#7D7D7D]",
                                "flex flex-row items-center gap-2 px-4 "
                              )}
                            >
                              <img src={gram} alt='telegram' />{" "}
                              <span className=' font-medium text-base leading-[19px]'>
                                Telegram
                              </span>
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </li>
            </ul>
          </div>
        </div>
        {/* MOBILE VERSION END */}

        <Link
          to='https://app.dexpay.io'
          target='_blank'
          className='flex flex-column justify-center items-center'
        >
          <button className=' flex flex-column justify-center items-center bg-dimRed px-[18px] py-[16px] rounded-full hover:bg-pink duration-300 sm:flex hidden text-dimWhite text-sm font-medium'>
            Launch App
          </button>
        </Link>
      </nav>
    </div>
  )
}

export default Navbar
