import { Fragment } from "react";
import Hero from "../components/heroNew";
import HowItWork from "../components/howItWork";
import WhyDex from "../components/whyDex";
import ShortFaq from "../components/shortFaq/shortFaq";

const Home = () => {
  return (
    <div className="bg-[#111217]">
      <div className="max-w-[1350px] mx-auto">
        <Hero />
        <HowItWork />
        <WhyDex />
        <ShortFaq />
      </div>
    </div>
  );
};

export default Home;
