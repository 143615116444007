import React from "react"
import { Collapse } from "react-collapse"
import { arrowUp, arrowDown } from "../../assets"

const FaqDetail = ({ open, toggle, question, solution }) => {
  return (
    <div className='max-md:max-w-[664px]  mx-auto max-w-[998px] max-sm:justify-center max-sm:items-center max-sm:gap-[72px] flex flex-col items-start p-0 gap-12'>
      {/* Opened QA's */}
      <div className='max-sm:gap-6 flex flex-col items-between p-0 gap-[55px]'>
        {/* Head QA */}
        <div className=' max-sm:max-w-[334px] max-md:gap-[105px] max-w-[998px] flex flex-row justify-between items-center p-0 max-sm:gap-[43px] gap-[439px]  '>
          <p
            className='max-sm:max-w-[274px] max-sm:text-base text-[21px] max-sm:leading-[19px]  leading-[25px] font-medium text-[#979797]  cursor-pointer'
            onClick={toggle}
          >
            {question}
          </p>
          <div className='border-box flex flex-row items-start gap-2 cursor-pointer '>
            <span
              className='max-sm:w-[24px] max-sm:h-[24px] max-sm:p-[8px]  w-[36px] h-[36px] max-sm:border-[0.5px]  p-3 border-[1px] border-solid border-dimWhite rounded-[32px]'
              onClick={toggle}
            >
              <img
                src={open ? arrowUp : arrowDown}
                alt='arrow up'
                className='max-sm:w-[8px] max-sm:h-[8px] w-[12px] h-[12px] '
              />
            </span>
          </div>
        </div>
        <Collapse isOpened={open}>
          {/* Horizontal-line 1 */}
          <hr className=' max-w-[998px] max-sm:max-w-[334px] h-0 border-solid border-[1px] border-[#1E2029] self-stretch grow-0 flex-none' />
          <p className='max-sm:max-w-[334px] max-sm:text-base max-w-[998px] max-sm:leading-[24px] font-medium text-[#E5E5E5] text-[21px] leading-8 my-4'>
            {solution}
          </p>
          {/* Hozontal-line 2 */}
          <hr className='max-sm:max-w-[334px] max-w-[998px] h-0 border-solid border-[1px] border-[#1E2029] self-stretch grow-0 flex-none' />
        </Collapse>
      </div>
      {/* End of Opened QA's */}
    </div>
  )
}

export default FaqDetail
