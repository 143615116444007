export const faqData = [
  // 1
  {
    question: " What is DexPay?",
    solution:
      "DexPay is a decentralized peer-to-peer cryptocurrency exchange where users can buy and sell crypto using fiat currency with their non-custodial wallets.",
  },
  // 2
  {
    question: "What problem is DexPay trying to solve?",
    solution:
      "We have seen a rise in the number of prominent exchanges being closed down in recent times. These exchanges, as crucial as they are, have proven that they cannot be completely trusted to be the sole fiat gateways to crypto. DexPay fulfills this crucial role in a way that emphasizes the ethos of blockchain and decentralization. ",
  },
  // 3
  {
    question: "What is a decentralized Peer-to-Peer trading platform?",
    solution:
      "A decentralized peer-to-peer trading platform allows for trustless trades between two users secured by a smart-contract. In DexPay, we use this solution to provide a new and better way to carry out crypto on-ramp and off-ramp transactions.",
  },
  // 4
  {
    question: " How does it differ from a centralized trading platform?",
    solution:
      "You may be familiar with the saying “Not your keys, not your assets”. DexPay protocol does not, at any point, have access to user’s funds or wallet unlike centralized exchanges/platforms. All transactions carried out on DexPay are completely on-chain and executed by smart-contracts interaction.",
  },
  // 5

  {
    question: "What blockchains does DexPay support?",
    solution:
      "The DexPay platform is currently built on top of the Binance Smart-Chain (BSC) with support for other blockchains coming soon.",
  },
  // 6
  {
    question: "What assets can I trade on DexPay?",
    solution:
      "You can trade USDT and USDC on DexPay protocol.  Support for other assets is coming soon.",
  },
  // 7
  {
    question: "How do I buy/sell on DexPay?",
    solution:
      "Connect to the DexPay platform with your wallet (app.dexpay.io), click on p2p express and input the amount you wish to buy/sell .",
  },

  // 8
  {
    question: " Is there a limit to the volume that I can trade on DexPay?",
    solution:
      "Yes, there is a lower limit of $5 and an upper limit of $1000 per trade.",
  },

  // 9
  {
    question: " What are Merchants?",
    solution:
      "Merchants are users with excellent trade history who have completed KYC and provide liquidity on the DexPay platform to enhance smooth buying and selling of assets.",
  },

  // 10
  {
    question: " How can I become a merchant?",
    solution:
      "You can become a merchant by applying to be one via the “Apply to become a merchant” button on your profile page. For more information on DexPay merchant, please visit Merchant FAQ",
  },

  // 11
  {
    question: "  How are merchants rated?",
    solution:
      "Merchants are rated based on the quality of their trades. Things we consider are the payment time, completed trades, feedback from the users and Disputes.",
  },

  // 12
  {
    question: "Can i post Ads on DexPay?",
    solution:
      "Yes, all users can create ads on DexPay, however, only merchants will have the verified badge on their ads. ",
  },

  // 13
  {
    question: " How does dispute resolution work?",
    solution:
      "When a trade is unable to be completed, one of either parties can start a dispute resolution process. Dispute resolution is managed through a dispute channel on telegram where a support representative will settle the dispute case. For more information, you can read more about our dispute resolution process on our documentation.",
  },
  // 14
  {
    question: "Can I open a dispute on already completed trades?",
    solution:
      "No, the dispute process can only be opened for uncompleted trades. You cannot start a dispute after confirming that you have received payment and completing the trade. Users must make sure that the trade terms are adhered to and satisfactory before they complete the trades. ",
  },
]

////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////

export const feedbackData = [
  // 1
  {
    question: " What is DexPay?",
    solution:
      "DexPay is a decentralized peer-to-peer cryptocurrency exchange where users can buy and sell crypto using fiat currency with their non-custodial wallets.",
  },
  // 2
  {
    question: "What problem is DexPay trying to solve?",
    solution:
      "We have seen a rise in the number of prominent exchanges being closed down in recent times. These exchanges, as crucial as they are, have proven that they cannot be completely trusted to be the sole fiat gateways to crypto. DexPay fulfills this crucial role in a way that emphasizes the ethos of blockchain and decentralization. ",
  },
  // 3
  {
    question: "What is a decentralized Peer-to-Peer trading platform?",
    solution:
      "A decentralized peer-to-peer trading platform allows for trustless trades between two users secured by a smart-contract. In DexPay, we use this solution to provide a new and better way to carry out crypto on-ramp and off-ramp transactions.",
  },
  // 4
  {
    question: " How does it differ from a centralized trading platform?",
    solution:
      "You may be familiar with the saying “Not your keys, not your assets”. DexPay protocol does not, at any point, have access to user’s funds or wallet unlike centralized exchanges/platforms. All transactions carried out on DexPay are completely on-chain and executed by smart-contracts interaction.",
  },
]
