import Assets from "./assets";

const { NAIRA_ICON, USDT_ICON, DUSD_ICON, USDC_ICON, FDC_ICON } = Assets;

export const getCurrencyImage = (currency) => {
    switch (currency) {
      case "NGN":
        return NAIRA_ICON;
      case "FDC":
        return FDC_ICON;
        break;
      case "USDT":
        return USDT_ICON;
        break;
      case "USDC":
        return USDC_ICON;
        break;
      case "DUSD":
        return DUSD_ICON;
        break;
      case "mUSD":
        return DUSD_ICON;
        break;
      default:
        return USDT_ICON;
    }
  };