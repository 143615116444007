// Library
import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Pages
import Home from "./pages";
import FAQ from "./pages/faq/faq";
import Contact from "./pages/contact/contact";
import TechnicalPaper from "./pages/technicalPaper";
import ReactGA from "react-ga";

//Components
import Navbar from "./components/Navbar";
import Footer from "./components/footer/footer";
import Copyright from "./components/copyright";
import ScrollToTop from "./components/ScrollToTop";
//import Marquee from "react-fast-marquee";

const App = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_TAG);
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <>
      {/* <Marquee
        style={{
          backgroundColor: "#111217",
          fontFamily: "Gellix"
        }}
      >
        <div className="bg-[#111217] font-semibold  mt-4  w-full text-white">
          ⚠️ DexPay does not support Naira (NGN) P2P trading due to Government issued directive on all platforms.
        </div>
      </Marquee> */}
      <ScrollToTop />
      <Routes>
        <Route path="/technicalPaper" element={<TechnicalPaper />} />
      </Routes>
      <div className="bg-[#111217]">
        <div className="min-w-[350px] w-[100%] px-[16px] md:max-w-[1350px] mx-auto">
          <Navbar />
        </div>
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
      <Copyright />
    </>
  );
};

export default App;
