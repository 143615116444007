import React from "react"
import Hr from "./hr"
import { price, success, trade, wallet2, customer } from "../assets"

const HowItWork = () => {
  return (
    <div className=' w-full h-full '>
      <div className='mx-auto items-center flex flex-col  text-center text-dimWhite gap-[64px] p-0'>
        <div className='mt-[160px] max-sm:mt-[97.74px] flex flex-col items-center p-0 gap-[16px]'>
          <h4 className=' font-medium max-sm:text-[20px] text-center max-sm:leading-[24px] leading-[34px] text-[28px]'>
            A simple way to trade your assets
          </h4>
          <p className=' max-w-[790px] font-normal max-sm:max-w-[334px] max-sm:text-[16px]  text-[21px] max-sm:leading-[19px]  leading-[25px]'>
            Trade securely and quickly on our decentralized platform. Take
            control of your assets and transactions.
          </p>
        </div>
        {/* How it work Boxes */}
        <div className=' flex  max-sm:items-center flex-wrap max-w-[1132px] justify-center flex-row items-start  p-0 max-sm:gap-6 gap-12 mb-[116px]'>
          {/* Box 1 */}
          <div className='border-solid border-2  border-dimGray backdrop-blur-[20px] rounded-lg p-2 gap-2 bg-darkred w-[188px] h-[185px] flex flex-col justify-center'>
            <div className=' absolute top-[16px] left-[11px] rounded-lg bg-[#1A1C23] w-[40px] h-[40px] hidden md:block'>
              <p className='text-[#B1B1B1] text-base font-medium my-[10.5px] mx-[17px] text-center'>
                1
              </p>
            </div>
            <img
              className='max-sm:w-[48px] max-sm:h-[48px] w-[76px] h-[76px] mx-auto mt-[35px]'
              src={wallet2}
              alt=''
            />
            <div className='mt-[26px] mb-[29px]'>
              <p className=' max-sm:text-sm text-dimWhite  text-center font-medium text-base leading-[19px]'>
                Connect your Wallet
              </p>
            </div>
          </div>
          {/* Box 2 */}
          <div className='border-solid border-2  border-dimGray backdrop-blur-[20px] rounded-lg p-2 gap-2 bg-darkred w-[188px] h-[185px] flex flex-col justify-center'>
            <div className=' absolute top-[16px] left-[11px] rounded-lg bg-[#1A1C23] w-[40px] h-[40px] hidden md:block'>
              <p className='text-[#B1B1B1] text-base font-medium my-[10.5px] mx-[17px] text-center'>
                2
              </p>
            </div>
            <img
              className='max-sm:w-[48px] max-sm:h-[48px] w-[76px] h-[76px] mx-auto mt-[35px]'
              src={price}
              alt=''
            />
            <div className='mt-[26px] mb-[29px]'>
              <p className='max-sm:text-sm text-dimWhite  text-center font-medium text-base leading-[19px]'>
                See the Best Rate
              </p>
            </div>
          </div>
          {/* Box 3 */}
          <div className='border-solid border-2  border-dimGray backdrop-blur-[20px] rounded-lg p-2 gap-2 bg-darkred w-[188px] h-[185px] flex flex-col justify-center'>
            <div className=' absolute top-[16px] left-[11px] rounded-lg bg-[#1A1C23] w-[40px] h-[40px] hidden md:block'>
              <p className='text-[#B1B1B1] text-base font-medium my-[10.5px] mx-[17px] text-center'>
                3
              </p>
            </div>
            <img
              className='max-sm:w-[48px] max-sm:h-[48px] w-[76px] h-[76px] mx-auto mt-[35px]'
              src={trade}
              alt=''
            />
            <div className='mt-[26px] mb-[29px]'>
              <p className='max-sm:text-sm text-dimWhite  text-center font-medium text-base leading-[19px]'>
                Buy or Sell your crypto
              </p>
            </div>
          </div>
          {/* Box 4 */}
          <div className='border-solid border-2  border-dimGray backdrop-blur-[20px] rounded-lg p-2 gap-2 bg-darkred w-[188px] h-[185px] flex flex-col justify-center'>
            <div className=' absolute top-[16px] left-[11px] rounded-lg bg-[#1A1C23] w-[40px] h-[40px] hidden md:block'>
              <p className='text-[#B1B1B1] text-base font-medium my-[10.5px] mx-[17px] text-center'>
                4
              </p>
            </div>
            <img
              className='max-sm:w-[48px] max-sm:h-[48px] w-[76px] h-[76px] mx-auto mt-[35px]'
              src={success}
              alt=''
            />
            <div className='mt-[26px] mb-[29px]'>
              <p className='max-sm:text-sm text-dimWhite  text-center font-medium text-base leading-[19px]'>
                Successful
              </p>
            </div>
          </div>
          {/* Box 5 */}
          <div className='border-solid border-2  border-dimGray backdrop-blur-[20px] rounded-lg  gap-2 bg-darkred w-[188px] h-[185px] flex flex-col justify-center'>
            <div className=' absolute top-[16px] left-[11px] rounded-lg bg-[#1A1C23] w-[40px] h-[40px] hidden md:block'>
              <p className='text-[#B1B1B1] text-base font-medium my-[10.5px] mx-[17px] text-center'>
                5
              </p>
            </div>
            <img
              className='max-sm:w-[48px] max-sm:h-[48px] w-[76px] h-[76px] mx-auto mt-[35px]'
              src={customer}
              alt=''
            />
            <div className='mt-[26px] mb-[29px]'>
              <p className='max-sm:text-sm text-dimWhite text-center font-medium text-base leading-[19px]'>
                24/7 Customer support
              </p>
            </div>
          </div>
        </div>
      </div>
      <Hr />
    </div>
  )
}

export default HowItWork
