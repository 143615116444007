import React from "react"

const Copyright = () => {
  return (
    <div className='bg-[#16171D] w-full h-full '>
      <p className='text-[#B1B1B1] max-sm:text-sm text-center py-[25px] font-medium text-base leading-[16px] bg-[#16171D] max-sm:leading-[17px]'>
        All rights reserved
      </p>
    </div>
  )
}

export default Copyright
