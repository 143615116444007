import React, { useState } from "react"
import styles from "../../style"
import { faqData } from "../../store"
import MoreQuestions from "../../components/moreQuestions"
import FaqDetail from "./faqDetail"

const FAQ = () => {
  const [open, setOpen] = useState(false)
  const toggle = (index) => {
    if (open === index) {
      return setOpen(null)
    }

    setOpen(index)
  }
  return (
    <div
      name='faq'
      className={`w-full h-full flex flex-col items-center justify-center ${styles.faqCover}`}
    >
      <div className='flex flex-col items-center p-0 gap-6'>
        <h4
          className='max-sm:mt-[64px] max-sm:text-[20px] text-dimWhite m mt-[128px] font-semibold text-[32px] 
        text-center leading-[38px] max-sm:leading-[28px] tracking-[0.01em]'
        >
          Frequently Asked Questions
        </h4>
        <p className=' text-dimWhite max-w-[803px] max-md:text-xl max-sm:mb-[64px] max-md:mb-[60px] max-md:max-w-[507px] max-sm:max-w-[296px] max-sm:text-sm mb-[120px] text-2xl leading-[29px] max-sm:leading-[17px] font-normal text-center tracking-[0.01em] '>
          Get answers to your questions about DexPay and resolve any issues with
          ease
        </p>
      </div>

      {faqData.map((data, index) => {
        return (
          <FaqDetail
            key={index}
            open={index === open}
            question={data.question}
            solution={data.solution}
            toggle={() => toggle(index)}
          />
        )
      })}
      <MoreQuestions />
    </div>
  )
}

export default FAQ
