const baseUrl =
  "https://res.cloudinary.com/dfxwwg0cm/image/upload/v1678698564/dexp2p-app";
const Assets = {
  //png
  SUCCESS: `${baseUrl}/png/success_p3psba.gif`,
  BACKGROUND_PNG: `${baseUrl}/png/background_zwpspt.png`,
  TICK_CIRCLE: `${baseUrl}/png/tick-circle_ibxxgj.png`,
  CLOSE_CIRCLE_PNG: `${baseUrl}/png/close-circle_gtcfom.png`,
  CHAT: `${baseUrl}/png/chat_ewyd4d.png`,
  FLAG_PNG: `${baseUrl}/png/flag_l34lff.png`,

  //svg
  ADD_WALLET: `${baseUrl}/svg/add-wallet.svg`,
  DUSD_ICON: `${baseUrl}/svg/dUSD_Icon_czgnhz.png`,
  USDC_ICON: `${baseUrl}/svg/usd-coin-usdc-logo-t_wvhgoj`,

  ACTIVITY: `${baseUrl}/svg/activity.svg`,
  CANCELLED: `${baseUrl}/svg/cancelled.svg`,
  BACKGROUND: `${baseUrl}/svg/background_ioljkm.svg`,
  COMPLETED: `${baseUrl}/svg/completed_fgbs7q.svg`,
  FLAG: `${baseUrl}/svg/flag_xvjatw.svg`,
  LOGO: `${baseUrl}/svg/logo_xjyyxj.svg`,
  FILTER: `${baseUrl}/svg/filter_uhi7bu.svg`,
  POLYGON: `${baseUrl}/svg/polygon_d5b4gy.svg`,
  NOTIFICATION: `${baseUrl}/svg/notification_mkfyxa.svg`,
  SEARCH: `${baseUrl}/svg/search_unca6z.svg`,
  BRAVE: `${baseUrl}/svg/brave_zzbgpu.svg`,
  ARROW_DOWN: `${baseUrl}/svg/arrow-down_xalaqh.svg`,
  ARROW_LEFT: `${baseUrl}/svg/arrow-left_qecofx.svg`,
  FRAME: `${baseUrl}/svg/frame_g5yt7q.svg`,
  ARROW_SWAP: `${baseUrl}/svg/arrow-swap_wxjujw.svg`,
  DIVIDER: `${baseUrl}/svg/divider_jaqx35.svg`,
  METAMASK: `${baseUrl}/svg/metamask_gdhbcw.svg`,
  LINE: `${baseUrl}/svg/line_wrtvik.svg`,
  NEGATIVE: `${baseUrl}/svg/negative_vthhwu.svg`,
  NOTE: `${baseUrl}/svg/note_ntbnau.svg`,
  TRUST_WALLET: `${baseUrl}/svg/trustwallet_wzztqa.svg`,
  POSITIVE: `${baseUrl}/svg/positive_spm7x0.svg`,
  USER: `${baseUrl}/svg/user_qd86s5.svg`,
  WARNING: `${baseUrl}/svg/warning_wf9vjq.svg`,
  BANK: `${baseUrl}/svg/bank_s2ddso.svg`,
  WALLET_CONNECT: `${baseUrl}/svg/walletconnect_qecrgm.svg`,
  VECTOR: `${baseUrl}/svg/Vector_esfhdu.svg`,
  ADD_CIRCLE: `${baseUrl}/svg/add-circle_m2kq2y.svg`,
  CLOSE_CIRCLE: `${baseUrl}/svg/close-circle_zudjwh.svg`,
  LOGIN: `${baseUrl}/svg/login_f4utcv.svg`,
  MONEY_RECEIVE: `${baseUrl}/svg/money-recive_zd6pnn.svg`,
  LAYER: `${baseUrl}/svg/layer_gy7udb.svg`,
  BADGE: `${baseUrl}/svg/badge_trvdht.svg`,
  MONEY_SEND: `${baseUrl}/svg/money-send_lhcswg.svg`,
  THUMBS_DOWN: `${baseUrl}/svg/thumbs-down_gjcqpk.svg`,
  THUMBS_UP: `${baseUrl}/svg/thumbs-up_to0lnf.svg`,
  APPROVE: `${baseUrl}/svg/approve_ol2dg2.svg`,
  ARROW_RIGHT: `${baseUrl}/svg/arrow-right_fbgf4r.svg`,
  DISPUTE: `${baseUrl}/svg/dispute_wjxqps.svg`,
  RECEIVED: `${baseUrl}/svg/received_jt27ia.svg`,
  MESSAGE: `${baseUrl}/svg/message_qwlyvm.svg`,
  SEND: `${baseUrl}/svg/send_grcnpf.svg`,
  CRYPTO: `${baseUrl}/svg/crypto_wwxnam.svg`,
  TRADE: `${baseUrl}/svg/trade_v6x7pn.svg`,
  POLYGON_LOGO: `${baseUrl}/svg/matic_vji2q1.svg`,
  WALLET_CONNECT_LOGO: `${baseUrl}/svg/wallet-connect_nqiup2.svg`,
  COINBASE_LOGO: `${baseUrl}/svg/coinbase-wallet-logo_vjcj5g.svg`,
  NAIRA_ICON: `${baseUrl}/svg/ngn_j2rm8m.svg`,
  USDT_ICON: `${baseUrl}/svg/Layer_1usdt_zynroi.svg`,
  COPY_ICON: `${baseUrl}/svg/copy_unv2cu.svg`,
  SUCCESS_ICON: `${baseUrl}/svg/Iconerror_b7ofuz.svg`,
  ERROR_ICON: `${baseUrl}/svg/Iconsuccess_z7shnp.svg`,
  MENU_ICON: `${baseUrl}/svg/Iconmore_c2ubsa.svg`,
  BSC_LOGO: `${baseUrl}/svg/bnb-logo_znlq3i.svg`,
  FEE_ICON: `${baseUrl}/svg/fee-icon_tfo69u.svg`,
  REFRESH_ICON: `${baseUrl}/svg/refresh-square-2refresh_wdpfl7.svg`,
  FDC_ICON: `https://res.cloudinary.com/dfxwwg0cm/image/upload/v1715241632/dexp2p-app/png/IMG_0919_wkmcu9.png`,
};

export default Assets;
