import logo from "./logo.svg"
import close from "./close.svg"
import closeModal from "./closeModal.svg"
import menu from "./menu.svg"
import cover from "./cover.png"
import headerImg from "./headerImg.png"
import imgFoot1 from "./imgFoot1.png"
import imgFoot2 from "./imgFoot2.png"
import price from "./price.gif"
import success from "./success.gif"
import customer from "./customer.gif"
import trade from "./trade.gif"
import wallet2 from "./wallet2.gif"
import minimize from "./minimize.svg"
import zap from "./zap.svg"
import phone from "./phone.svg"
import shield from "./shield.svg"
import star from "./star.svg"
import solana from "./solana.svg"
import polygonScan from "./polygonScan.svg"
import binance from "./binance.svg"
import compoundLabs from "./compoundLabs.svg"
import walletConnect from "./walletConnect.svg"
import tradingView from "./tradingView.svg"
import arrowRight from "./arrowRight.svg"
import user from "./user.svg"
import badge from "./badge.svg"
import arrowSwap from "./arrowSwap.svg"
import arrowRi from "./arrowRi.png"
import arrowLi from "./arrowLi.png"
import ellipse7 from "./ellipse7.png"
import ellipse8 from "./ellipse8.png"
import ellipse9 from "./ellipse9.png"
import ellipse10 from "./ellipse10.png"
import ellipse11 from "./ellipse11.png"
import arrowUp from "./arrowUp.png"
import arrowDown from "./arrowDown.png"
import dexp2pLogo from "./dexp2pLogo.svg"
import discus from "./discus.png"
import reddit from "./reddit.png"
import telegram from "./telegram.png"
import twitter from "./twitter.png"
import youtube from "./youtube.png"
import github from "./github.png"
import meta from "./meta.png"
import medium from "./medium.png"
import instagram from "./instagram.png"
import utube from "./utube.png"
import gram from "./gram.png"
import discod from "./discod.png"
import midi from "./midi.png"
import tweet from "./tweet.png"
import bird from "./bird.svg"
import Paper from "./Technical-Paperv1.0.pdf"
import pdfIcon from "./pdfIcon.svg"
import cryptoIcons from "./svgs/cryptoIcons.svg"
import nairaIcon from "./svgs/nairaIcon.svg"
import usdtIcon from "./svgs/usdtIcon.svg"

export {
  price,
  success,
  customer,
  trade,
  wallet2,
  logo,
  menu,
  close,
  closeModal,
  cover,
  headerImg,
  imgFoot1,
  imgFoot2,
  minimize,
  zap,
  shield,
  star,
  phone,
  binance,
  tradingView,
  polygonScan,
  compoundLabs,
  solana,
  walletConnect,
  arrowRight,
  arrowSwap,
  badge,
  user,
  arrowLi,
  arrowRi,
  arrowUp,
  arrowDown,
  ellipse7,
  ellipse8,
  ellipse9,
  ellipse10,
  ellipse11,
  dexp2pLogo,
  discus,
  reddit,
  telegram,
  twitter,
  youtube,
  medium,
  instagram,
  meta,
  github,
  tweet,
  utube,
  midi,
  gram,
  discod,
  bird,
  Paper,
  pdfIcon,
  cryptoIcons,
  nairaIcon,
  usdtIcon
}

// DexP2P-Technical-Paper-v1.0
