import React from "react"
import styles from "../../style"
import ContactForm from "./contactForm"
import Hr from "../../components/hr"

const Contact = () => {
  return (
    <div
      name='contact-us'
      className={` w-full h-full relative  ${styles.contactCover}`}
    >
      <div className='flex flex-col items-center p-0 gap-6'>
        <h4 className='max-sm:mt-[64px] max-md:text-normal max-sm:text-[20px] max-sm:mb-[5px]  mt-[100px]  font-semibold text-dimWhite text-[32px] leading-[38px] text-center tracking-[0.01em] '>
          Contact Us
        </h4>
        <p className='max-sm:mb-[35px] mb-[64px] max-sm:text-sm max-sm:max-w-[296px] max-md:max-w-[507px] max-w-[803px] max-md:text-base font-normal text-[24px] leading-[29px] text-center text-dimWhite tracking-[0.01em]'>
          For questions, support, or partnership, please reach out to us and our
          team will get in touch with you
        </p>
      </div>
      <div className='pb-[96px] max-sm:pb-[64px] '>
        <ContactForm />
      </div>
      <Hr />
    </div>
  )
}

export default Contact
