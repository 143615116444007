import React from "react"

const ContactForm = () => {
  const [formData, setFormData] = React.useState({})

  const { fullName, companyName, email, website, twitter, telegram, message } =
    formData

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const mail = `mailto:contact@dexpay.io?subject=${fullName} from ${companyName}&body=${message}%0D%0DTwitter: [${twitter}]%0DTelegram: [${telegram}]%0DWebsite: [${website}]`

  return (
    <div className=' w-[1020px] h-[703px] max-sm:h-[917px] max-md:w-[592px] max-md:h-[917px] border-box max-sm:w-[328px] mx-auto flex flex-col  items-start px-1 py-8 gap-2  bg-formColor border-2 border-solid border-[#1E2029] backdrop-blur-lg rounded-lg '>
      {/* info */}
      <div className='max-w-[924px] max-md:max-w-[578px] max-md:h-[917px] m-auto max-sm:max-w-[232px] max-sm:m-auto max-md:m-auto flex flex-col items-start p-0 gap-8  max-md:gap-4'>
        {/* address 1 */}
        <div className='max-sm:flex-col max-md:flex-col  flex flex-row mx-auto items-start p-0 gap-6 '>
          {/* Name */}
          <div className=' flex flex-col justify-center items-start p-0 gap-3'>
            <label className='flex flex-col justify-center items-start p-0 gap-3 font-medium text-base max-sm:text-[13px] leading-[19px] text-[#cbcbcb] '>
              Name
              <div className='  flex flex-row items-center  gap-2 '>
                <input
                  type='name'
                  name='fullName'
                  value={fullName}
                  onChange={(e) => onChange(e)}
                  className='p-4 border-box border-[1px] border-solid border-[#1E2029] rounded-[32px] bg-formColor max-sm:text-[13px] font-medium text-sm  leading-[17px] text-[#494949] max-sm:w-[232px] max-sm:h-[48px] w-[450px] h-[49px] self-stretch'
                  placeholder='Input your name'
                />
              </div>
            </label>
          </div>
          {/* Company */}
          <div className=' flex flex-col justify-center items-start p-0 gap-3'>
            <label className='flex flex-col justify-center items-start p-0 gap-3 font-medium max-sm:text-[13px] text-base leading-[19px] text-[#cbcbcb] '>
              Company
              <div className=' flex flex-row items-center gap-2  max-sm:text-[13px] '>
                <input
                  type='name'
                  name='companyName'
                  value={companyName}
                  onChange={(e) => onChange(e)}
                  className='border-box p-4 self-stretch border-[1px] border-solid border-[#1E2029] rounded-[32px] bg-formColor max-sm:text-[13px] font-medium text-sm leading-[17px] text-[#494949] w-[450px] h-[49px]  max-sm:w-[232px] max-sm:h-[48px] '
                  placeholder='Input your company’s name'
                />
              </div>
            </label>
          </div>
        </div>
        {/* address 2 */}
        <div className=' max-sm:flex-col max-md:flex-col flex flex-row mx-auto items-start p-0 gap-6'>
          {/* Name */}
          <div className=' flex flex-col justify-center items-start p-0 gap-3'>
            <label className='flex flex-col justify-center items-start p-0 gap-3 font-medium max-sm:text-[13px] text-base leading-[19px] text-[#cbcbcb] '>
              Email Address
              <div className=' flex flex-row items-center  gap-2  '>
                <input
                  type='email'
                  name='email'
                  value={email}
                  onChange={(e) => onChange(e)}
                  className='border-box p-4 border-[1px] border-solid border-[#1E2029] rounded-[32px] bg-formColor font-medium text-sm leading-[17px] text-[#494949] max-sm:text-[13px]  max-sm:w-[232px] max-sm:h-[48px] w-[450px] h-[49px] self-stretch '
                  placeholder='Input your email address'
                />
              </div>
            </label>
          </div>
          {/* Company */}
          <div className=' flex flex-col justify-center items-start p-0 gap-3'>
            <label className='flex flex-col justify-center items-start p-0 gap-3 font-medium max-sm:text-[13px] text-base leading-[19px] text-[#cbcbcb] '>
              Website
              <div className=' flex flex-row items-center gap-2'>
                <input
                  type='name'
                  name='website'
                  value={website}
                  onChange={(e) => onChange(e)}
                  className='border-box p-4 border-[1px] border-solid border-[#1E2029] rounded-[32px] bg-formColor font-medium text-sm leading-[17px] text-[#494949] max-sm:text-[13px]  max-sm:w-[232px] max-sm:h-[48px] w-[450px] h-[49px] self-stretch '
                  placeholder='Input your website'
                />
              </div>
            </label>
          </div>
        </div>
        {/* address 3 */}
        <div className='  max-sm:flex-col max-md:flex-col flex flex-row mx-auto items-start p-0 gap-6'>
          {/* Name */}
          <div className=' flex flex-col justify-center items-start p-0 gap-3'>
            <label className='flex flex-col justify-center items-start p-0 gap-3 font-medium max-sm:text-[13px] text-base leading-[19px] text-[#cbcbcb] '>
              Twitter
              <div className=' flex flex-row items-center gap-2'>
                <input
                  type='name'
                  name='twitter'
                  value={twitter}
                  onChange={(e) => onChange(e)}
                  className='border-box p-4 border-[1px] border-solid border-[#1E2029] rounded-[32px] bg-formColor font-medium text-sm leading-[17px] text-[#494949] max-sm:text-[13px]  max-sm:w-[232px] max-sm:h-[48px] w-[450px] h-[49px] self-stretch '
                  placeholder='Input your twitter username'
                />
              </div>
            </label>
          </div>
          {/* Company */}
          <div className=' flex flex-col justify-center items-start p-0 gap-3'>
            <label className='flex flex-col justify-center items-start p-0 gap-3 font-medium max-sm:text-[13px] text-base leading-[19px] text-[#cbcbcb] '>
              Telegram
              <div className='flex flex-row items-center gap-2'>
                <input
                  type='name'
                  name='telegram'
                  value={telegram}
                  onChange={(e) => onChange(e)}
                  className='border-box p-4 border-[1px] border-solid border-[#1E2029] rounded-[32px] bg-formColor font-medium text-sm leading-[17px] text-[#494949] max-sm:text-[13px]  max-sm:w-[232px] max-sm:h-[48px] w-[450px] h-[49px] self-stretch'
                  placeholder='Input your telegram username'
                />
              </div>
            </label>
          </div>
        </div>

        {/* textarea field */}
        <div className='flex flex-col justify-center items-start p-0 gap-3 '>
          <label className='text-base max-sm:text-[13px] font-medium leading-[19px] text-[#cbcbcb]'>
            Type message
          </label>
          <textarea
            name='message'
            value={message}
            onChange={(e) => onChange(e)}
            id=''
            cols=''
            rows=''
            placeholder='Input your message here'
            className='max-md:w-[450px] max-md:h-[101px] max-sm:w-[232px] max-sm:h-[112px] w-[924px] h-[151px] bg-formColor border-box flex flex-row items-center p-4 gap-2 border-solid border-[1px] border-[#1E2029] rounded-2xl self-stretch text-[#494949] font-medium text-sm leading-[17px] max-sm:text-[13px]'
          />
        </div>
        {/* Button */}
        <a
          href={mail}
          className='w-[924] h-[57px] flex flex-row justify-center items-center py-[20px] px-[18px] gap-2 bg-dimWhite rounded-[64px] self-stretch'
        >
          <span className='font-medium text-sm leading-[17px] text-primary'>
            Submit
          </span>
        </a>
      </div>
    </div>
  )
}

export default ContactForm
