const styles = {
  gradientCover: "bg-[#111217] ",
  sidebar:
    "animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both",
  bgImage: "absolute bg-[#111217]from-primary via-tertiary to-tertiary",
  radialCover:
    "bg-[#111217] from-tertiary via-primary to-primary overflow-hidden",
  faqCover: "bg-[#111217] from-primary via-tertiary to-primary",
  contactCover: "bg-[#111217] from-primary via-tertiary to-primary",
}

export default styles
