import React from "react"
import { Link } from "react-router-dom"
import {
  dexp2pLogo,
  discus,
  reddit,
  telegram,
  twitter,
  youtube,
  medium,
  instagram,
  meta,
  github,
} from "../../assets"

const FooterMenu = () => {
  return (
    <div className='my-[121px] max-sm:ml-[33px] max-sm:my-[70px] max-sm:gap-[60px] max-md:gap-[60px]  flex-wrap flex flex-row items-start p-0 gap-[201px] '>
      {/* Logo */}
      <div className=' flex flex-row items-center p-0 gap-[24px]'>
        <img
          src={dexp2pLogo}
          className='w-[43px] h-[43px] '
          alt='DexPay logo'
        />
        <h4 className='font-bold text-[21px] leading-[25px] text-dimWhite uppercase'>
          DexPay
        </h4>
      </div>
      {/* Mobile responsive version */}
      <div className='  flex flex-row justify-between items-start p-0 gap-[41px] max-sm:gap-[45px]'>
        {/* menus */}
        <div className='max-sm:gap-[50px] flex flex-row items-start p-0 gap-[23px]'>
          {/* menu 1  */}
          <div className='flex flex-col items-start p-0 gap-[16px]'>
            <Link
              to='https://docs.dexpay.io'
              className=' w-[144px] h-[35px] flex flex-row items-center gap-[8px] max-sm:py-[6px] max-sm:px-[4px] max-sm:w-[40px] max-sm:h-[26px] py-2 px-[18px] rounded-[64px] font-medium max-sm:text-[12px] text-base leading-[19px] text-dimWhite'
            >
              Learn
            </Link>

            <Link
              to='https://medium.com/@dexpay'
              className='w-[144px] h-[35px] max-sm:py-[6px] max-sm:px-[4px] max-sm:w-[42px] max-sm:h-[26px] flex flex-row items-center py-2 px-[18px] rounded-[64px] gap-2 font-medium max-sm:text-[12px] text-base leading-[19px] text-dimWhite'
            >
              About
            </Link>
          </div>
          {/* menu 2 */}
          <div className='flex flex-col items-start p-0 gap-[16px]'>
            <Link
              to='/faq'
              className='w-[144px] h-[35px] max-sm:w-[36px] max-sm:h-[26px] max-sm:py-[6px] max-sm:px-[4px] flex flex-row items-center py-2 px-[18px] rounded-[64px] gap-2 font-medium max-sm:text-[12px] text-base leading-[19px] text-dimWhite'
            >
              FAQs
            </Link>
            <Link
              to='https://docs.dexpay.io/getting-started/quick-steps'
              className='w-[144px] h-[35px] max-sm:w-[47px] max-sm:h-[26px] max-sm:py-[6px] max-sm:px-[4px] flex flex-row items-center py-2 px-[18px] rounded-[64px] gap-2 font-medium max-sm:text-[12px] text-base leading-[19px] text-dimWhite'
            >
              Guides
            </Link>
          </div>
        </div>
        {/* social */}
        <div className='flex flex-col justify-center items-start p-0 gap-[27px]'>
          {/*  title   */}
          <p className=' max-sm:text-[12px] font-medium leading-[19px] text-base text-dimWhite'>
            Follow us
          </p>
          <div className='flex flex-col items-start p-0 gap-[16px]'>
            {/* soci g1 */}
            <div className='flex flex-row items-start p-o gap-[16px]'>
              <a
                href='https://discord.gg/Bf76y58GDJ'
                target='_blank'
                className='max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]'
              >
                <img
                  src={discus}
                  className=' max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]'
                  alt=''
                />
              </a>
              {/*  <a
                href='/'
                className=' max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]'
              >
                <img
                  src={reddit}
                  className=' max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]'
                  alt='reddit'
                />
              </a> */}
              <a
                href='https://t.me/usedexpay'
                target='_blank'
                className='max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]'
              >
                <img
                  src={telegram}
                  className=' max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]'
                  alt=''
                />
              </a>
              <a
                href='https://twitter.com/usedexpay'
                target='_blank'
                className='max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]'
              >
                <img
                  src={twitter}
                  className=' max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]'
                  alt=''
                />
              </a>
              {/*  <a
                href='https://www.youtube.com/channel/UCkXV_rQv_ez_FTJm3LpEtXA'
                target='_blank'
                className='max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]'
              >
                <img
                  src={youtube}
                  className=' max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]'
                  alt=''
                />
              </a> */}
            </div>
            {/* soci g2 */}
            <div className='flex flex-row items-start p-0 gap-[16px] '>
              {/* social link pt2 */}

              <a
                href='https://medium.com/@dexpay'
                target='_blank'
                className='max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]'
              >
                <img
                  src={medium}
                  className=' max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]'
                  alt=''
                />
              </a>
              <a
                href='https://www.instagram.com/usedexpay/'
                target='_blank'
                className='max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]'
              >
                <img
                  src={instagram}
                  className=' max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]'
                  alt=''
                />
              </a>
              {/*  <a
                href="https://web.facebook.com/profile.php?id=100089968512456"
                className="max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]"
              >
                <img
                  src={meta}
                  className=" max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]"
                  alt=""
                />
              </a> */}
              {/* <a
                href="https://github.com/DEXP2P"
                target="_blank"
                className="max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]"
              >
                <img
                  src={github}
                  className=" max-sm:w-[18px] max-sm:h-[18px] w-[28px] h-[28px]"
                  alt=""
                />
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterMenu
//  max-sm:mt-[70px]
// max-sm:mb-[40px]
