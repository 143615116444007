import React from "react"
import styles from "../style"
import Hr from "./hr"

const MoreQuestions = () => {
  return (
    <div className={` w-full h-full $${styles.faqCover}`}>
      <div className=' mt-[73px] max-sm:mt-[50px] flex flex-col items-center p-0 gap-[18px]'>
        <h4 className=' max-sm:text-[19px] font-medium text-center text-[28px] leading-[34px] text-dimWhite'>
          Have more questions?
        </h4>
        <p className='max-sm:max-w-[277px]  max-sm:text-[13px] mt-4 max-w-[790px] mb-[77px] font-normal text-[21px] leading-[25px] text-center text-dimWhite'>
          Reach out to us at{" "}
          <span className='text-pink'>
            {" "}
            <a href='mailto:contact@dexp2p.com'> contact@dexp2p.com </a>{" "}
          </span>{" "}
          for more answers or visit our{" "}
          <span className='text-pink'>
            {" "}
            <a href='https://medium.com/@dexpay' target='_blank'>
              {" "}
              Medium{" "}
            </a>{" "}
          </span>{" "}
          page
        </p>
      </div>
      <Hr />
    </div>
  )
}

export default MoreQuestions
