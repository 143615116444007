import React from "react";
import styles from "../style";
import { zap, star, shield, phone, minimize } from "../assets";

const WhyDex = () => {
  return (
    <div className={`w-full h-full ${styles.radialCover}`}>
      <div className="max-sm:gap-16 flex flex-col max-sm:mb-[100px] max-sm:mt-[100px] mt-[138px] mx-auto mb-[138px] items-center  p-0 gap-12">
        <h4 className="max-sm:text-[20px] text-center text-dimWhite text-[28px] leading-[34px] max-sm:leading-[24px] font-medium ">
          Why DexPay?
        </h4>
        {/* Why DexPay Boxes */}
        <div className="max-sm:flex max-sm:flex-col max-sm:gap-6 gap-[32px] flex flex-row items-start justify-center flex-wrap p-0 ">
          {/* Why DexPay Box 1 */}
          <div className="w-[331px] h-[280px] box-border border-[#1E2029] border-solid border-2 rounded-lg bg-darkred backdrop-blur-[20px] flex flex-col gap-[24px] pt-[34px] pl-[24px]">
            {/*  */}

            <div
              className="flex flex-row items-start p-[16px] gap-[8px] bg-[#1E2029] rounded-lg border-[#1E2029] 
            border-solid border-[1px] w-[56px] h-[56px] "
            >
              <img src={zap} className="m-auto w-[24px] h-[24px]" alt="zap" />
            </div>
            <div className="flex flex-col items-start p-0 gap-[12px]">
              <h3 className="text-base text-center leading-[19px] text-dimWhite font-medium ">
                Fast
              </h3>
              <p className="max-w-[280px] text-[#CBCBCB] leading-[17px] text-sm  font-normal">
                Users P2P transactions to BUY and SELL assets get executed
                instantly based on best rate and users trade offers. No delay,
                trade requests and notifications are sent instantly between the
                trade parties and orders are finalized as users make approvals.
              </p>
            </div>
          </div>
          {/* Why DexPay Box 2 */}
          <div className="w-[331px] h-[280px] box-border border-[#1E2029] border-solid border-2 rounded-lg bg-darkred backdrop-blur-[20px] flex flex-col gap-[24px] pt-[34px] pl-[24px]">
            {/*  */}

            <div
              className="flex flex-row items-start p-[16px] gap-[8px] bg-[#1E2029] rounded-lg border-[#1E2029] 
            border-solid border-[1px] w-[56px] h-[56px] "
            >
              <img
                src={shield}
                className="m-auto w-[24px] h-[24px]"
                alt="shield"
              />
            </div>
            <div className="flex flex-col items-start p-0 gap-[12px]">
              <h3 className="text-base text-center leading-[19px] text-dimWhite font-medium ">
                Secured
              </h3>
              <p className="max-w-[280px] text-[#CBCBCB] leading-[17px] text-sm  font-normal">
                Customer assets are secured via a multi-signatory binding
                contract that allows users to receive funds once all the trade
                conditions have been met. DexPay does not hold user’s funds nor
                store users' details.
              </p>
            </div>
          </div>
          {/* Why DexPay Box 3 */}
          <div className="w-[331px] h-[280px] box-border border-[#1E2029] border-solid border-2 rounded-lg bg-darkred backdrop-blur-[20px] flex flex-col gap-[24px] pt-[34px] pl-[24px]">
            {/*  */}

            <div
              className="flex flex-row items-start p-[16px] gap-[8px] bg-[#1E2029] rounded-lg border-[#1E2029] 
            border-solid border-[1px] w-[56px] h-[56px] "
            >
              <img src={star} className="m-auto w-[24px] h-[24px]" alt="star" />
            </div>
            <div className="flex flex-col items-start p-0 gap-[12px]">
              <h3 className="text-base text-center leading-[19px] text-dimWhite font-medium ">
                Best Rates
              </h3>
              <p className="max-w-[280px] text-[#CBCBCB] leading-[17px] text-sm  font-normal">
                Exchange rates on DexPay are set by users. These rates are
                competitive and reflect the demand/supply of an asset in real
                time.
              </p>
            </div>
          </div>
          {/* Why DexPay Box 4 */}
          <div className="w-[331px] h-[280px] box-border border-[#1E2029] border-solid border-2 rounded-lg bg-darkred backdrop-blur-[20px] flex flex-col gap-[24px] pt-[34px] pl-[24px]">
            {/*  */}

            <div
              className="flex flex-row items-start p-[16px] gap-[8px] bg-[#1E2029] rounded-lg border-[#1E2029] 
            border-solid border-[1px] w-[56px] h-[56px] "
            >
              <img
                src={minimize}
                className="m-auto w-[24px] h-[24px]"
                alt="minimize"
              />
            </div>
            <div className="flex flex-col items-start p-0 gap-[12px]">
              <h3 className="text-base text-center leading-[19px] text-dimWhite font-medium ">
                Decentralized
              </h3>
              <p className="max-w-[280px] text-[#CBCBCB] leading-[17px] text-sm  font-normal">
                DexPay is a decentralized marketplace that empowers users to
                trade crypto/fiat without giving up control of their assets. All
                transactions are carried out from the safety of your
                non-custodial wallet, and DexPay never takes your funds!
              </p>
            </div>
          </div>
          {/* Why DexPay Box 5 */}
          <div className="w-[331px] h-[280px] box-border border-[#1E2029] border-solid border-2 rounded-lg bg-darkred backdrop-blur-[20px] flex flex-col gap-[24px] pt-[34px] pl-[24px]">
            {/*  */}

            <div
              className="flex flex-row items-start p-[16px] gap-[8px] bg-[#1E2029] rounded-lg border-[#1E2029] 
            border-solid border-[1px] w-[56px] h-[56px] "
            >
              <img
                src={phone}
                className="m-auto w-[24px] h-[24px]"
                alt="phone"
              />
            </div>
            <div className="flex flex-col items-start p-0 gap-[12px]">
              <h3 className="text-base text-center leading-[19px] text-dimWhite font-medium ">
                24/7 Customer Support
              </h3>
              <p className="max-w-[280px] text-[#CBCBCB] leading-[17px] text-sm  font-normal">
                We ensure Users get the best experience they deserve when using
                DexPay, Users complaints or requests are instantly attended to
                via a secured live chat portal on Telegram.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyDex;
