import React from "react"
import FooterMenu from "./footerMenu"
import Hr from "../hr"

const Footer = () => {
  return (
    <div className='bg-product-gradient flex flex-col items-center  w-full h-full'>
      <Hr />
      {/* Footer menu */}
      <FooterMenu />
      {/* Copyright section */}
    </div>
  )
}

export default Footer
