import React, { useState } from "react"
import { arrowRi } from "../../assets"
import { Link } from "react-router-dom"
import ShortFaqDetail from "./shortFaqDetail"
import Hr from "../hr"
import { feedbackData } from "../../store"

const ShortFaq = () => {
  const [open, setOpen] = useState(false)
  const toggle = (index) => {
    if (open === index) {
      return setOpen(null)
    }

    setOpen(index)
  }
  return (
    <div className='bg-product-gradient w-full h-full'>
      <Hr />
      <div className='max-sm:gap-[6px] flex flex-col justify-center items-center p-0 gap-[12px]'>
        <div className='flex flex-col mt-[84px] items-center p-0 gap-[16px]'>
          <h4 className='max-sm:text-[20px] max-md:text-[24px] max-sm:leading-[24px] text-dimWhite font-medium text-[28px] leading-[34px] text-center mb-[60px]  '>
            Frequently Asked Questions
          </h4>
        </div>

        {feedbackData.map((data, index) => {
          return (
            <ShortFaqDetail
              key={index}
              open={index === open}
              question={data.question}
              solution={data.solution}
              toggle={() => toggle(index)}
            />
          )
        })}

        <button className='bg-[#16171d] border-box border-solid border[1px] border-[#1e2029] w-[260px] h-[51px] rounded-[64px] flex flex-row justify-center items-center py-4 px-[18px] mb-[83px] mt-[40px]  gap-2 '>
          <span className='max-sm:text-sm text-dimWhite text-base font-medium leading-[19px]'>
            <Link to='/faq'>Read more</Link>
          </span>{" "}
          <img src={arrowRi} alt='arrown right' className='w-[12] h-[12]' />
        </button>
      </div>
      <Hr />
    </div>
  )
}

export default ShortFaq
