import ReactDOM from "react-dom"
import { useEffect } from "react"
import { closeModal } from "../assets"

const Modal = ({ onClose, children, actionBar }) => {
  useEffect(() => {
    document.body.classList.add("overflow-hidden")

    return () => {
      document.body.classList.remove("overflow-hidden")
    }
  }, [])

  return ReactDOM.createPortal(
    <div>
      <div
        onClick={onClose}
        className='fixed inset-0 bg-gray-300 opacity-50'
      ></div>

      <div
        className='fixed left-1/2 top-1/2 z-20 h-full w-full -translate-x-1/2 -translate-y-1/2 overflow-hidden  bg-white p-16  text-white focus:outline-none max-sm:max-h-[550px] 
       max-sm:w-[90vw] max-sm:max-w-[900px] sm:max-h-[550px] sm:w-[90vw] sm:max-w-[900px]
        '
      >
        <div className='h-full w-full flex flex-col  md:overflow-hidden  gap-4'>
          <button
            onClick={onClose}
            className='text-md text-black absolute right-0 top-0 z-10 flex h-10 w-10 items-center justify-center bg-pink  ring-2 ring-[#E3E3E3] focus:outline-none'
          >
            <img
              src={closeModal}
              alt='close'
              // className='w-[24px] h-[24px] max-sm:w-[14px] max-sm:h-[14px] object-contain cursor-pointer'
            />
          </button>
          {children}
         {/*  <div className='flex justify-start'>{actionBar}</div> */}
        </div>
      </div>
    </div>,
    document.querySelector(".modal-container")
  )
}

export default Modal

// <div className='flex flex-col justify-between h-full  '>
// max-sm:max-h-[550px]
// max-sm:max-w-[900px]
// <div className='fixed inset-40 p-10 bg-white max-w-[900px] max-h-[550px]'>
