import React from "react"
import styles from "../style"
import { cryptoIcons } from "../assets"
import JoinWaitlist from "./joinWaitlist"
import Hr from "./hr"
import ExpressConverter from "./expressConverter"

const Hero = () => {
  const handleProceedClick = () => {
    window.location.replace(
      `${process.env.REACT_APP_MAIN_APP_BASE_URL}?order=express&orderType=buy`
    )
  }
  return (
    <div className={`w-full h-full bg-[#111217]`}>
      <div className='flex flex-row  items-start justify-evenly flex-wrap sm:flex-nowrap pt-[36px] md:pt-[64px]'>
        <div className='flex flex-col text-center md:text-left p-0 mb-4'>
          {/* Heading */}
          <div className='flex flex-col justify-start items-center md:items-start mb-4'>
            <h1 className='font-semibold text-center md:text-left leading-[40px] md:leading-[62px] text-white text-[34px] md:text-[46px] mb-2 md:mb-3'>
              On-Chain P2P Trading <br /> Powering{" "}
              <span className='text-pink'> Crypto </span> &lt;&gt;{" "}
              <span className='text-pink'>Fiat</span> <br /> Exchange & Payment
            </h1>
            <p className='text-[#BEBEBE] text-sm md:text-lg font-normal text-center md:text-left'>
              Buy and sell crypto securely from your self custody wallet <br />
              without risk of losing your funds
            </p>
          </div>
          <div className='flex flex-row justify-center md:justify-start items-start'>
            <img
              src={cryptoIcons}
              className='md:max-w-[80%] h-[92px] md:h-[130px]'
              alt='crypto icons'
            />
          </div>

          <div className='hidden md:flex flex-row justify-center mt-6 mb-8'>
            {" "}
            <button
              onClick={handleProceedClick}
              className=' md:ml-[-120px] bg-[#FC3F6B] h-[55px] rounded-[64px] p-4 text-white text-sm mb-4 md:text-xl font-medium max-w-[206px] flex flex-col justify-center items-center'
            >
              Buy & Sell Crypto
            </button>
            {/*  <JoinWaitlist /> */}
          </div>

          {/* Join Waitlist */}
        </div>

        {/* P2PExpress Converter */}
        <ExpressConverter />
        {/* P2PExpress Converter End */}
      </div>
      <br /> <br /> <br /> <br />
      {/*End Hero Image  */}
      <Hr />
    </div>
  )
}

export default Hero
