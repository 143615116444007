/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react"
import { Row, Col } from "reactstrap"
import { Paper } from "../assets"

const TechnicalPaper = () => {
  return (
    <div className='text-center'>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <iframe
            style={{
              width: "100%",
              height: "800px",
            }}
            src={Paper}
          ></iframe>
        </Col>
      </Row>
    </div>
  )
}

export default TechnicalPaper
